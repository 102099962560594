// third party
import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
// local
import { DarkThemeService } from 'src/app/core/services/dark-theme/dark-theme.service';
import { HttpLoadingService } from 'src/app/core/services/http-loading/http-loading.service';
import { MenuOpenService } from 'src/app/core/services/menu-open/menu-open.service';
import { ProfileService } from 'src/app/core/services/profile/profile.service';
import { GraphApiProfile } from '../../services/profile/graph-api-profile';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public profile?: GraphApiProfile;
  public readonly applicationName: string = environment.applicationName;
  constructor(
    private authService: MsalService,
    public darkThemeService: DarkThemeService,
    public menuOpenService: MenuOpenService,
    private profileService: ProfileService,
    public loadingService: HttpLoadingService,
  ) { }

  ngOnInit(): void {
    this.profileService.getProfile().subscribe((profile) => {
      this.profile = profile;
    });
  }

  logout() {
      this.authService.logoutRedirect();
  }

  clickMenu () {
    this.menuOpenService.changeMenu();
  }

  changeTheme () {
    this.darkThemeService.changeTheme();
  }
}
