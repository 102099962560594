// third party
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
  MsalBroadcastService,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';

// local
// components
import { NavbarComponent } from 'src/app/core/components/navbar/navbar.component';
import { SideNavComponent } from 'src/app/core/components/side-nav/side-nav.component';
import { ThemeToggleComponent } from 'src/app/core/components/theme-toggle/theme-toggle.component';
// services
import { DarkThemeService } from 'src/app/core/services/dark-theme/dark-theme.service';
import { HttpInterceptorService } from 'src/app/core/services/http-interceptor/http-interceptor.service';
import { HttpLoadingService } from 'src/app/core/services/http-loading/http-loading.service';
import { InsightsService } from './services/insights/insights.service';
import { MenuOpenService } from 'src/app/core/services/menu-open/menu-open.service';
import { ProfileService } from 'src/app/core/services/profile/profile.service';


@NgModule({ declarations: [
        NavbarComponent,
        SideNavComponent,
        ThemeToggleComponent,
    ],
    exports: [
        NavbarComponent,
        SideNavComponent,
    ], imports: [
        // ANGULAR
        RouterModule,
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        // MATERIAL
        MatButtonModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatTooltipModule,
        // AUTH
        MsalModule], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true,
        },
        InsightsService,
        {
            provide: ErrorHandler,
            useClass: ApplicationinsightsAngularpluginErrorService,
        },
        DarkThemeService,
        HttpLoadingService,
        MenuOpenService,
        MsalService,
        MsalBroadcastService,
        ProfileService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class CoreModule { }
