import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DarkThemeService {

  public readonly isDarkTheme: BehaviorSubject<boolean>;

  constructor() { 
    this.isDarkTheme = new BehaviorSubject<boolean>(false);
  }

  changeTheme () {
    this.isDarkTheme.next(!this.isDarkTheme.getValue());
  }
}
