import { GraphApiProfile } from './graph-api-profile';


export class Profile {
  private readonly _roles: Set<string>;

  constructor(private readonly graphApiProfile: GraphApiProfile, roles: ReadonlyArray<string>) {
    this._roles = new Set<string>(roles);
  }

  hasRole(role: string): boolean {
    return this._roles.has(role);
  }

  get roles(): ReadonlyArray<string> {
    return Array.from(this._roles.keys());
  }

  get givenName(): string | undefined {
    return this.graphApiProfile.givenName;
  }

  get surname(): string | undefined {
    return this.graphApiProfile.surname;
  }

  get userPrincipalName(): string | undefined {
    return this.graphApiProfile.userPrincipalName;
  }

  get id(): string | undefined {
    return this.graphApiProfile.id;
  }
}
