// third party
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

// local
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';



@NgModule({
  declarations: [ PageNotFoundComponent ],
  imports: [
    CommonModule,
    RouterModule,
    // MATERIAL
    MatIconModule,
  ],
  exports: [ PageNotFoundComponent ]
})
export class PageNotFoundModule { }
