// third party
import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  InteractionStatus,
  RedirectRequest,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
// local
import { AuthStatusTypes } from 'src/app/core/models/auth-status-types';
import { InsightsService } from 'src/app/core/services/insights/insights.service';
import { DarkThemeService } from 'src/app/core/services/dark-theme/dark-theme.service';


enum ThemeTypes {
  LIGHT = "light-theme",
  DARK = "dark-theme"
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'angular-test-app';
  authStatus: AuthStatusTypes = AuthStatusTypes.LOADING;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private insights: InsightsService,
    public darkThemeService: DarkThemeService,
    public overlayContainer: OverlayContainer,
  ) { }

  ngOnInit(): void {
    this.insights.appInsights.trackTrace({ message: 'Track me' });
    this.insights.appInsights.trackEvent({ name: 'Tracking event...' });

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe(() => {
        this.setAuthStatus();
      });

    this.msalBroadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None), takeUntil(this._destroying$))
      .subscribe(() => {
        this.setAuthStatus();
        this.checkAndSetActiveAccount();
      });
  }

  setAuthStatus() {
    this.authStatus = AuthStatusTypes.LOADING;
    if (this.authService.instance.getAllAccounts().length > 0) {
      this.authStatus = AuthStatusTypes.AUTHENTICATED;
    } else {
      this.authStatus = AuthStatusTypes.UNAUTHENTICATED;
    }
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  setTheme(isDarkTheme: boolean): string {

    let theme = ThemeTypes.LIGHT;
    if (isDarkTheme) {
      theme = ThemeTypes.DARK;
      this.overlayContainer.getContainerElement().classList.remove(ThemeTypes.LIGHT);
      this.overlayContainer.getContainerElement().classList.add(ThemeTypes.DARK);
    } else {
      this.overlayContainer.getContainerElement().classList.remove(ThemeTypes.DARK);
      this.overlayContainer.getContainerElement().classList.add(ThemeTypes.LIGHT);
    }
    return theme;
  }

  get isLoading(): boolean {
    return this.authStatus === AuthStatusTypes.LOADING;
  }

  get isAuthenticated(): boolean {
    return this.authStatus === AuthStatusTypes.AUTHENTICATED;
  }

  get isUnAuthenticated(): boolean {
    return this.authStatus === AuthStatusTypes.UNAUTHENTICATED;
  }
}