import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuOpenService {

  public readonly isMenuOpen: BehaviorSubject<boolean>;

  constructor() { 
    this.isMenuOpen = new BehaviorSubject<boolean>(true);
  }

  changeMenu () {
    this.isMenuOpen.next(!this.isMenuOpen.getValue());
  }
}
