import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { CounterpartyTrade } from '../../market-blotter/view-market-blotter/counterparty-trade.model';

@Component({
  selector: 'app-deal-terms-modal',
  templateUrl: './deal-terms-modal.component.html',
  styleUrls: ['./deal-terms-modal.component.scss'],
  providers: [DatePipe]
})
export class DealTermsModalComponent {
  constructor(
    public dialogRef: MatDialogRef<DealTermsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { trades: CounterpartyTrade[] },
    public datePipe: DatePipe
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }

  formatDate(date: string): string {
    return this.datePipe.transform(date, 'mediumDate') || '';
  }
} 