// third party
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

// local
import { FailedComponent } from './failed/failed.component';
import { LoginScreenComponent } from './login-screen/login-screen.component';



@NgModule({

  declarations: [FailedComponent, LoginScreenComponent],
  imports: [
    CommonModule,
    MatButtonModule
  ],
  exports: [FailedComponent, LoginScreenComponent]
})
export class LoginModule { }
