import { Injectable } from '@angular/core';
import { CounterpartyService } from '../../summary-report/view-summary-report/counterparty.service';
import { Counterparty } from '../../summary-report/view-summary-report/counterparty.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CounterpartyMappingService {
    private counterpartiesSubject = new BehaviorSubject<Counterparty[]>([]);
    public counterparties$ = this.counterpartiesSubject.asObservable();
    private counterpartiesLoaded = false;

    constructor(private counterpartyService: CounterpartyService) {
        this.loadCounterparties();
    }

    private loadCounterparties(): void {
        this.counterpartyService.getCounterparties().subscribe({
            next: (counterparties) => {
                this.counterpartiesSubject.next(counterparties);
                this.counterpartiesLoaded = true;
            },
            error: (error) => {
                console.error('Error loading counterparties:', error);
                this.counterpartiesLoaded = true; // Mark as loaded even on error to prevent infinite retries
            }
        });
    }

    public getCounterpartyFullName(code: string): string {
        const counterparties = this.counterpartiesSubject.getValue();
        
        // Try to find a match in the loaded counterparties
        const counterparty = counterparties.find(cp => 
            cp.allegroCode === code || 
            cp.plattsCode === code || 
            cp.shortname === code
        );
        
        if (counterparty) {
            return counterparty.allegroName || counterparty.kplerCompanyName || code;
        }
        
        // Check if this code belongs to a parent company
        const childCounterparty = counterparties.find(cp => cp.mapToParent === code);
        if (childCounterparty) {
            const parentCounterparty = counterparties.find(cp => cp.allegroCode === childCounterparty.mapToParent);
            if (parentCounterparty) {
                return parentCounterparty.allegroName || parentCounterparty.kplerCompanyName || code;
            }
        }
        
        // If no match found, return the original code
        return code;
    }

    public findMatchingCounterpartyCodes(searchTerm: string): string[] {
        if (!searchTerm) return [];
        
        const counterparties = this.counterpartiesSubject.getValue();
        const searchTermLower = searchTerm.toLowerCase();
        const matchingCodes = new Set<string>();
        const debugMatches: any[] = [];
        
        console.log(`Searching for counterparties matching: "${searchTerm}"`);
        console.log(`Total counterparties loaded: ${counterparties.length}`);
        
        // First pass: Find direct matches
        counterparties.forEach(cp => {
            // Create an array of all fields to check
            const fields: {name: string, value: string}[] = [
                {name: 'allegroCode', value: cp.allegroCode},
                {name: 'allegroName', value: cp.allegroName},
                {name: 'plattsCode', value: cp.plattsCode},
                {name: 'shortname', value: cp.shortname},
                {name: 'kplerCompanyName', value: cp.kplerCompanyName},
                {name: 'otherTerms', value: cp.otherTerms},
                {name: 'otherTerms2', value: cp.otherTerms2},
                {name: 'otherTerms3', value: cp.otherTerms3}
            ].filter(field => field.value); // Filter out null/undefined values
            
            // Check if the search term matches any field
            const matchingFields = fields
                .filter(field => field.value.toLowerCase().includes(searchTermLower))
                .map(field => `${field.name}: ${field.value}`);
            
            // Check if any field exactly matches the search term
            const exactMatchFields = fields
                .filter(field => field.value.toLowerCase() === searchTermLower)
                .map(field => `${field.name}: ${field.value}`);
            
            const isMatch = matchingFields.length > 0;
            const isExactMatch = exactMatchFields.length > 0;
            
            if (isMatch || isExactMatch) {
                // For debugging
                debugMatches.push({
                    counterparty: {
                        allegroCode: cp.allegroCode,
                        allegroName: cp.allegroName,
                        plattsCode: cp.plattsCode,
                        shortname: cp.shortname,
                        otherTerms: cp.otherTerms,
                        otherTerms2: cp.otherTerms2,
                        otherTerms3: cp.otherTerms3
                    },
                    matchedOn: matchingFields,
                    exactMatch: isExactMatch
                });
                
                // Add this counterparty's codes
                if (cp.allegroCode) matchingCodes.add(cp.allegroCode.toLowerCase());
                if (cp.plattsCode) matchingCodes.add(cp.plattsCode.toLowerCase());
                if (cp.shortname) matchingCodes.add(cp.shortname.toLowerCase());
                
                // Add the search term itself as a matching code
                matchingCodes.add(searchTermLower);
                
                // FIXED: Split otherTerms into individual terms and add each one
                this.addTermsToMatchingCodes(cp.otherTerms, matchingCodes);
                this.addTermsToMatchingCodes(cp.otherTerms2, matchingCodes);
                this.addTermsToMatchingCodes(cp.otherTerms3, matchingCodes);
            }
        });
        
        // Second pass: Add parent and child relationships
        const initialMatchingCodes = Array.from(matchingCodes);
        
        initialMatchingCodes.forEach(matchedCode => {
            // Find the counterparty for this code
            const matchedCounterparty = counterparties.find(cp => 
                (cp.allegroCode && cp.allegroCode.toLowerCase() === matchedCode) || 
                (cp.plattsCode && cp.plattsCode.toLowerCase() === matchedCode) || 
                (cp.shortname && cp.shortname.toLowerCase() === matchedCode)
            );
            
            if (matchedCounterparty) {
                // Add children codes
                const childCounterparties = counterparties.filter(child => 
                    child.mapToParent === matchedCounterparty.allegroCode
                );
                
                if (childCounterparties.length > 0) {
                    console.log(`Found ${childCounterparties.length} children for ${matchedCounterparty.allegroName || matchedCounterparty.allegroCode}`);
                }
                
                childCounterparties.forEach(child => {
                    if (child.allegroCode) matchingCodes.add(child.allegroCode.toLowerCase());
                    if (child.plattsCode) matchingCodes.add(child.plattsCode.toLowerCase());
                    if (child.shortname) matchingCodes.add(child.shortname.toLowerCase());
                    
                    // FIXED: Add child's other terms
                    this.addTermsToMatchingCodes(child.otherTerms, matchingCodes);
                    this.addTermsToMatchingCodes(child.otherTerms2, matchingCodes);
                    this.addTermsToMatchingCodes(child.otherTerms3, matchingCodes);
                });
                
                // Add parent codes
                if (matchedCounterparty.mapToParent) {
                    const parentCounterparty = counterparties.find(parent => 
                        parent.allegroCode === matchedCounterparty.mapToParent
                    );
                    
                    if (parentCounterparty) {
                        console.log(`Found parent for ${matchedCounterparty.allegroName || matchedCounterparty.allegroCode}: ${parentCounterparty.allegroName || parentCounterparty.allegroCode}`);
                        
                        if (parentCounterparty.allegroCode) matchingCodes.add(parentCounterparty.allegroCode.toLowerCase());
                        if (parentCounterparty.plattsCode) matchingCodes.add(parentCounterparty.plattsCode.toLowerCase());
                        if (parentCounterparty.shortname) matchingCodes.add(parentCounterparty.shortname.toLowerCase());
                        
                        // FIXED: Add parent's other terms
                        this.addTermsToMatchingCodes(parentCounterparty.otherTerms, matchingCodes);
                        this.addTermsToMatchingCodes(parentCounterparty.otherTerms2, matchingCodes);
                        this.addTermsToMatchingCodes(parentCounterparty.otherTerms3, matchingCodes);
                    }
                }
            }
        });
        
        // Third pass: Check if the search term itself is a code
        // This handles the case where someone searches for "PCHK" directly
        const searchTermIsCode = counterparties.some(cp => 
            (cp.allegroCode && cp.allegroCode.toLowerCase() === searchTermLower) ||
            (cp.plattsCode && cp.plattsCode.toLowerCase() === searchTermLower) ||
            (cp.shortname && cp.shortname.toLowerCase() === searchTermLower)
        );
        
        if (searchTermIsCode) {
            matchingCodes.add(searchTerm);
        }
        
        console.log('Matching counterparty details:', debugMatches);
        console.log('Final matching codes:', Array.from(matchingCodes));
        
        // Convert all matching codes to lowercase for consistent case-insensitive matching
        const normalizedMatchingCodes = Array.from(matchingCodes).map(code => code.toLowerCase());
        
        console.log('Final matching codes (normalized):', normalizedMatchingCodes);
        
        return normalizedMatchingCodes;
    }

    // Add this helper method to properly split terms
    private addTermsToMatchingCodes(terms: string | null | undefined, matchingCodes: Set<string>): void {
        if (terms && terms.trim()) {
            // Split by comma and add each term individually
            terms.split(',').forEach(term => {
                const trimmedTerm = term.trim().toLowerCase();
                if (trimmedTerm) {
                    matchingCodes.add(trimmedTerm);
                }
            });
        }
    }
} 