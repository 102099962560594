// third party
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Observable, map, shareReplay } from 'rxjs';
// absoloute
import { environment } from 'src/environments/environment';
import { Profile } from './profile';
import { GraphApiProfile } from './graph-api-profile';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private profile?: Observable<Profile>;

  constructor(private readonly http: HttpClient, private readonly authService: MsalService) {
  }

  getProfile(): Observable<Profile> {
    if (!this.profile) {
      const activeAccount = this.authService.instance.getActiveAccount();
      if (!activeAccount) {
        throw new Error("No active account set. Please authenticate.");
      }
      const roles = activeAccount.idTokenClaims?.roles ?? [];
      this.profile = this.http
        .get<GraphApiProfile>(environment.microsoftProfileGraphApi)
        .pipe(map((graphApiProfile: GraphApiProfile) => new Profile(graphApiProfile, roles)))
        .pipe(shareReplay(1));
    }

    return this.profile;
  }
}
