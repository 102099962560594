// third party
import { Component } from '@angular/core';
// local
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent {
  public readonly applicationName: string = environment.applicationName;
}
