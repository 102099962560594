// third party
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// local
import { ProfileComponent } from './profile/profile.component';



@NgModule({
  declarations: [ ProfileComponent ],
  imports: [
    CommonModule
  ],
  exports: [ ProfileComponent ]
})
export class ProfileModule { }
