// third party
import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
// local
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.scss']
})
export class LoginScreenComponent implements OnInit {
  public readonly applicationName: string = environment.applicationName;

  constructor(private readonly authService: MsalService) {
  }

  ngOnInit(): void {
    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
  }

  login() {
    this.authService.loginRedirect();
  }
}
