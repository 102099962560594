// third party
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
// local
import { HttpLoadingService } from 'src/app/core/services/http-loading/http-loading.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(public httpLoadingService: HttpLoadingService) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.httpLoadingService.isLoading.next(true);
    return next.handle(req).pipe(
      finalize(
        () => {
          this.httpLoadingService.isLoading.next(false);
        }
      )
    );
  }
}
