import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CounterpartyService } from './counterparty.service';
import { Counterparty } from './counterparty.model';
import jsPDF from 'jspdf';
import { MessageService } from '../../market-blotter/view-market-blotter/message.service';
import { Message } from '../../market-blotter/view-market-blotter/messages.model';
import { DatePipe } from '@angular/common';
import { forkJoin } from 'rxjs';
import { TradeService } from '../../market-blotter/view-market-blotter/trade.service';
import { Trade } from '../../market-blotter/view-market-blotter/trade.model';
import { retry, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDatepicker, MatDateRangePicker } from '@angular/material/datepicker';
import { CounterpartyTradeService } from '../../market-blotter/view-market-blotter/counterparty-trade.service';
import { CounterpartyTrade } from '../../market-blotter/view-market-blotter/counterparty-trade.model';
import { CurrencyPipe } from '@angular/common';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { NativeDateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { DealTermsModalComponent } from '../deal-terms-modal/deal-terms-modal.component';

interface FilteredMessage {
  text: string;
  header: string;
  timestamp: string;
}

interface MessageGroup {
  name: string;
  messages: FilteredMessage[];
}

interface FilteredMessages {
  regular: any[];
  freight: any[];
  origination: any[];
  marketNews: any[];
}

interface PlattsTradesSummary {
  transactionMonth: string;
  buyQty: number;
  sellQty: number;
  numberOfTrades: number;
  netChange?: number;
}

const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

// Update the custom adapter class
export class CustomDateAdapter extends NativeDateAdapter {
  override format(date: Date, displayFormat: Object): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
}

@Component({
  selector: 'app-summary-report',
  templateUrl: './summary-report.component.html',
  styleUrls: ['./summary-report.component.scss'],
  providers: [
    DatePipe, 
    CurrencyPipe,
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DateAdapter, useClass: CustomDateAdapter }
  ]
})
export class SummaryReportComponent implements OnInit {
  counterparties: Counterparty[] = [];
  selectedCounterparty: Counterparty | null = null;
  loading = false;
  error: string | null = null;
  messages: { [key: string]: Message[] } = {};
  freightChatMessages: { [key: string]: Message[] } = {};
  originationChatMessages: { [key: string]: Message[] } = {};
  marketNewsMessages: { [key: string]: Message[] } = {};
  filteredMessages: FilteredMessages = {
    regular: [],
    freight: [],
    origination: [],
    marketNews: []
  };
  isLoadingMessages = false;
  dateRange: string = '';
  trades: Trade[] = [];
  filteredTrades: Trade[] = [];
  isLoadingTrades = false;
  tradeError: string | null = null;
  startDate: Date;
  endDate: Date;
  dateRangeForm: FormGroup;
  @ViewChild('dateRangeTrigger') dateRangeTrigger!: ElementRef;
  plattsTrades: CounterpartyTrade[] = [];
  filteredPlattsTrades: {
    physical: PlattsTradesSummary[];
    financial: PlattsTradesSummary[];
  } = {
    physical: [],
    financial: []
  };
  isLoadingPlattsTrades = false;
  plattsTradeError: string | null = null;

  // Add new properties for section collapse states
  sectionStates = {
    counterpartyDetails: true,
    physicalMarket: true,
    freightMarket: true,
    originationMarket: true,
    marketNews: true,
    jeraGmTrades: true,
    plattsPhysical: true,
    plattsFinancial: true
  };

  // Add this property to your component class
  includeRelatedCompanies = true;

  constructor(
    private counterpartyService: CounterpartyService,
    private messageService: MessageService,
    private tradeService: TradeService,
    private plattsTradeService: CounterpartyTradeService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private currencyPipe: CurrencyPipe,
    private dialog: MatDialog
  ) {
    this.endDate = new Date();
    this.startDate = new Date();
    this.startDate.setMonth(this.startDate.getMonth() - 6);
    this.dateRangeForm = this.fb.group({
      start: [{value: this.startDate, disabled: this.isLoading}],
      end: [{value: this.endDate, disabled: this.isLoading}]
    });
    this.updateDateRangeString();

    // Subscribe to form changes
    this.dateRangeForm.valueChanges.subscribe(value => {
      if (value.start && value.end) {
        this.onDateRangeChange(value.start, value.end);
      }
    });
  }

  ngOnInit(): void {
    this.loadCounterparties();
    this.loadAllMessages();
    this.loadTrades();
    this.loadPlattsTrades();
  }

  loadCounterparties(): void {
    this.loading = true;
    this.error = null;
    
    this.counterpartyService.getCounterparties()
      .subscribe({
        next: (data) => {
          this.counterparties = data;
          this.loading = false;
        },
        error: (error) => {
          this.error = 'Failed to load counterparties';
          this.loading = false;
          console.error('Error loading counterparties:', error);
        }
      });
  }

  get isLoading(): boolean {
    return this.loading || this.isLoadingMessages || 
           this.isLoadingTrades || this.isLoadingPlattsTrades;
  }

  onCounterpartySelect(event: Event): void {
    if (this.isLoading) {
      return;
    }

    const select = event.target as HTMLSelectElement;
    const selectedId = select.value;
    console.log('Selected counterparty ID:', selectedId);
    this.selectedCounterparty = this.counterparties.find(cp => cp.id === selectedId) || null;
    console.log('Selected counterparty object:', this.selectedCounterparty);
    this.filterMessagesForCounterparty();
    this.filterTradesForCounterparty();
    this.filterPlattsTradesForCounterparty();
  }

  generatePDF(): void {
    if (!this.selectedCounterparty) return;

    try {
      const doc = new jsPDF();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      const margin = 15;
      let yPosition = margin;

      // Colors
      const colors = {
        primary: { r: 0, g: 48, b: 87 },        // Dark blue
        secondary: { r: 128, g: 128, b: 128 },  // Gray
        accent: { r: 70, g: 130, b: 180 },      // Steel blue
        light: { r: 240, g: 240, b: 240 },      // Light gray
        white: { r: 255, g: 255, b: 255 }       // White
      };

      // Helper function to add text with word wrap - tighter line height
      const addWrappedText = (text: string, x: number, y: number, maxWidth: number, fontSize: number = 10): number => {
        doc.setFontSize(fontSize);
        const lines = doc.splitTextToSize(text, maxWidth);
        const lineHeight = doc.getTextDimensions('Test').h * 1.0; // Reduced from 1.05
        lines.forEach((line: string, index: number) => {
          doc.text(line, x, y + (index * lineHeight));
        });
        return lines.length * lineHeight;
      };

      // Helper function to check page break
      const checkPageBreak = (neededSpace: number): void => {
        if (yPosition + neededSpace > pageHeight - margin) {
          doc.addPage();
          yPosition = margin;
        }
      };

      // Header
      doc.setFillColor(colors.primary.r, colors.primary.g, colors.primary.b);
      doc.rect(0, 0, pageWidth, 25, 'F'); // Reduced from 30
      
      doc.setTextColor(colors.white.r, colors.white.g, colors.white.b);
      doc.setFontSize(20);
      doc.setFont('helvetica', 'bold');
      doc.text('Summary Report', pageWidth / 2, 17, { align: 'center' }); // Adjusted from 20

      yPosition = 35; // Reduced from 40

      // Date Range
      doc.setTextColor(colors.secondary.r, colors.secondary.g, colors.secondary.b);
      doc.setFontSize(10);
      doc.setFont('helvetica', 'normal');
      doc.text(`Summary Period: ${this.dateRange}`, pageWidth / 2, yPosition, { align: 'center' });
      yPosition += 12; // Reduced from 15

      // Counterparty Details Section
      doc.setFillColor(colors.primary.r, colors.primary.g, colors.primary.b);
      doc.rect(margin, yPosition - 6, pageWidth - (margin * 2), 12, 'F');
      doc.setTextColor(colors.white.r, colors.white.g, colors.white.b);
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.text('Counterparty Details', margin + 5, yPosition + 2);
      yPosition += 15; // Reduced from 20

      // Details grid
      doc.setTextColor(colors.primary.r, colors.primary.g, colors.primary.b);
      doc.setFontSize(11);
      const details = [
        { label: 'Allegro Name', value: this.selectedCounterparty.allegroName || 'N/A' },
        { label: 'Approved For', value: this.selectedCounterparty.approvedFor || 'N/A' },
        { label: 'Exposure Limit', value: this.selectedCounterparty.exposureLimit ? 
          `$${this.selectedCounterparty.exposureLimit.toLocaleString()}` : 'N/A' },
        { label: 'LNG MSA', value: this.selectedCounterparty.lngMsa === 'True' ? 
          this.selectedCounterparty.msaLink : 'Not Found' }      ];

      details.forEach(detail => {
        checkPageBreak(10);
        doc.setFont('helvetica', 'bold');
        doc.text(`${detail.label}:`, margin + 5, yPosition);
        doc.setFont('helvetica', 'normal');
        
        // Special handling for LNG MSA to wrap long text
        if (detail.label === 'LNG MSA') {
          const textHeight = addWrappedText(detail.value, margin + 80, yPosition, 
            pageWidth - (margin * 2) - 85, 10);
          yPosition += Math.max(6, textHeight);
        } else {
          doc.text(detail.value, margin + 80, yPosition);
          yPosition += 6;
        }
      });

      yPosition += 10; 

      // Market Intelligence Sections
      const messageSections = [
        { title: 'Physical Market News', messages: this.filteredMessages.regular },
        { title: 'Freight Market News', messages: this.filteredMessages.freight },
        { title: 'Origination Market News', messages: this.filteredMessages.origination },
        { title: 'Market News', messages: this.filteredMessages.marketNews }
      ];

      for (const section of messageSections) {
        checkPageBreak(15); 
        
        // Section Header
        doc.setFillColor(colors.primary.r, colors.primary.g, colors.primary.b);
        doc.rect(margin, yPosition - 6, pageWidth - (margin * 2), 12, 'F');
        doc.setTextColor(colors.white.r, colors.white.g, colors.white.b);
        doc.setFontSize(14);
        doc.setFont('helvetica', 'bold');
        doc.text(section.title, margin + 5, yPosition + 2);
        yPosition += 10;

        if (section.messages.length === 0) {
          checkPageBreak(10);
          doc.setTextColor(colors.secondary.r, colors.secondary.g, colors.secondary.b);
          doc.setFontSize(10);
          doc.setFont('helvetica', 'italic');
          doc.text('No messages found', margin + 5, yPosition);
          yPosition += 6; 
        } else {
          for (const message of section.messages) {
            checkPageBreak(25);

            // Calculate content height first
            const dateStr = this.datePipe.transform(message.date, 'MMM d, y h:mm a');
            const messageText = this.decodeURIComponent(message.message);
            
            // Pre-calculate text heights
            doc.setFontSize(10);
            const estimatedHeight = doc.getTextDimensions(messageText, {
              maxWidth: pageWidth - (margin * 2) - 10
            }).h + 15; // Increased padding

            // Draw message box with minimal padding
            doc.setFillColor(colors.light.r, colors.light.g, colors.light.b);
            doc.setDrawColor(colors.secondary.r, colors.secondary.g, colors.secondary.b);
            doc.roundedRect(margin, yPosition - 2, pageWidth - (margin * 2), estimatedHeight + 10, 2, 2, 'FD');

            // Message header - compact format
            doc.setTextColor(colors.primary.r, colors.primary.g, colors.primary.b);
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(10);
            doc.text(`${dateStr} - ${message.user} | ${message.subject}`, margin + 5, yPosition + 4);
            yPosition += 12; 

            // Message content
            doc.setTextColor(colors.primary.r, colors.primary.g, colors.primary.b);
            doc.setFont('helvetica', 'normal');
            const textHeight = addWrappedText(messageText, margin + 5, yPosition, 
              pageWidth - (margin * 2) - 10, 10);
            
            yPosition += textHeight + 8; 
          }
        }
        yPosition += 15; 
      }

      // Add JeraGM Trades Section with consistent header
      checkPageBreak(25);
      
      // Section Header
      doc.setFillColor(colors.primary.r, colors.primary.g, colors.primary.b);
      doc.rect(margin, yPosition - 6, pageWidth - (margin * 2), 12, 'F');
      doc.setTextColor(colors.white.r, colors.white.g, colors.white.b);
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.text('JeraGM Trades', margin + 5, yPosition + 2);
      yPosition += 15;

      if (this.filteredTrades.length === 0) {
        doc.setTextColor(colors.secondary.r, colors.secondary.g, colors.secondary.b);
        doc.setFontSize(10);
        doc.setFont('helvetica', 'italic');
        doc.text('No trades found', margin + 5, yPosition);
        yPosition += 10;
      } else {
        // Group trades by trade ID
        const groupedTrades = this.groupTradesByTradeId(this.filteredTrades);

        groupedTrades.forEach(trades => {
          checkPageBreak(12); // Reduced from 15
          
          doc.setFillColor(255, 255, 255);
          doc.setDrawColor(238, 238, 238);
          
          const trade = trades[0]; // Use first trade for common details
          
          // Calculate trade details text first
          let tradeText = `JeraGM ${trade.positionType} ${trade.incoterms} ${trade.location}`;
          
          // Add trade reference if exists
          if (trade.customTradeReference || trade.customPositionReference) {
            tradeText += ` "${trade.customTradeReference || trade.customPositionReference}"`;
          }

          // Add delivery period based on number of cargoes
          if (trades.length > 1) {
            const startDate = this.formatDateToMMMyy(trade.begTime);
            const endDate = this.formatDateToMMMyy(trade.endTime);
            tradeText += ` ${startDate} - ${endDate}`;
            tradeText += ` (${trades.length} cargo strip)`;
          } else {
            const deliveryDate = this.formatDateToMMMyy(trade.begTime);
            tradeText += ` ${deliveryDate}`;
          }

          // Calculate the height needed for the trade details with reduced line height
          doc.setFontSize(10);
          const maxWidth = pageWidth - (margin * 2) - 80; // Increased available width
          const tradeTextLines = doc.splitTextToSize(tradeText, maxWidth);
          const lineHeight = doc.getTextDimensions('Test').h * 0.9; // Reduced line height
          const tradeTextHeight = tradeTextLines.length * lineHeight;

          // Draw the background rectangle with minimal padding
          doc.roundedRect(margin, yPosition - 1, pageWidth - (margin * 2), tradeTextHeight + 6, 1, 1, 'FD');

          // Add trade details with reduced spacing
          doc.setTextColor(51, 51, 51);
          doc.setFont('helvetica', 'normal');
          tradeTextLines.forEach((line: string, index: number) => {
            doc.text(line, margin + 5, yPosition + 4 + (index * lineHeight));
          });

          // Add trade date on the right side
          const tradeDateStr = `Trade Date: ${this.datePipe.transform(trade.tradeDate, 'MMM d, yyyy')}`;
          doc.setFont('helvetica', 'normal');
          doc.text(tradeDateStr, pageWidth - margin - 5 - doc.getTextWidth(tradeDateStr), yPosition + 4);
          
          yPosition += tradeTextHeight + 8; // Reduced spacing between trades
        });
        
        yPosition += 8; // Reduced spacing after trades section
      }

    // Platts Trades Section
    const plattsTypes = [
      { title: 'Platts MOC Physical Trades', data: this.filteredPlattsTrades.physical },
      { title: 'Platts Financial Trades', data: this.filteredPlattsTrades.financial }
    ];

    plattsTypes.forEach(type => {
      checkPageBreak(25);
      
      // Section Header - matching other sections with blue background
      doc.setFillColor(colors.primary.r, colors.primary.g, colors.primary.b);
      doc.rect(margin, yPosition - 6, pageWidth - (margin * 2), 12, 'F');
      doc.setTextColor(colors.white.r, colors.white.g, colors.white.b);
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.text(type.title, margin + 5, yPosition + 2);
      yPosition += 15;

      if (type.data.length === 0) {
        doc.setTextColor(colors.secondary.r, colors.secondary.g, colors.secondary.b);
        doc.setFontSize(10);
        doc.setFont('helvetica', 'italic');
        doc.text('No trades found', margin + 5, yPosition);
        yPosition += 10;
      } else {
        // Table header
        const columns = ['Transaction Month', 'Buy Qty', 'Sell Qty', 'Net Change', 'Trades'];
        const colWidth = (pageWidth - (margin * 2)) / 5;
        
        // Header background
        doc.setFillColor(248, 249, 250); // #f8f9fa
        doc.rect(margin, yPosition, pageWidth - (margin * 2), 8, 'F');
        
        // Header text
        doc.setFontSize(11);
        doc.setTextColor(73, 80, 87); // #495057
        columns.forEach((col, i) => {
          doc.text(col, margin + (colWidth * i) + 5, yPosition + 6);
        });
        yPosition += 8;

        // Table rows
        type.data.forEach((row, index) => {
          checkPageBreak(8);
          
          // Row background (alternating)
          if (index % 2 === 0) {
            doc.setFillColor(252, 252, 252);
            doc.rect(margin, yPosition, pageWidth - (margin * 2), 8, 'F');
          }

          // Row data
          doc.setFont('helvetica', 'normal');
          doc.setTextColor(33, 37, 41); // #212529
          doc.text(row.transactionMonth, margin + 5, yPosition + 6);
          doc.text(row.buyQty.toString(), margin + colWidth + 5, yPosition + 6);
          doc.text(row.sellQty.toString(), margin + (colWidth * 2) + 5, yPosition + 6);
          doc.text(row.netChange?.toString() || '0', margin + (colWidth * 3) + 5, yPosition + 6);
          doc.text(row.numberOfTrades.toString(), margin + (colWidth * 4) + 5, yPosition + 6);

          yPosition += 8;
        });
        
        yPosition += 15;
      }
      
      yPosition += 15;
    });

      // Save the PDF
      const fileName = `summary-report-${this.selectedCounterparty.allegroCode}-${new Date().toISOString().split('T')[0]}.pdf`;
      doc.save(fileName);

    } catch (error) {
      console.error('Error generating PDF:', error);
      this.error = 'Failed to generate PDF';
    }
  }

  private loadAllMessages() {
    console.log('Starting loadAllMessages');
    this.isLoadingMessages = true;
    
    // Load all messages once, store them unfiltered
    forkJoin({
      regular: this.messageService.getMessages(),
      freight: this.messageService.getFreightChatMessages(),
      origination: this.messageService.getOriginationChatMessages(),
      marketNews: this.messageService.getMarketNewsMessages()
    }).subscribe({
      next: (result) => {
        console.log('Messages loaded:', {
          regularCount: Object.keys(result.regular).length,
          freightCount: Object.keys(result.freight).length,
          originationCount: Object.keys(result.origination).length,
          marketNewsCount: Object.keys(result.marketNews).length
        });
        // Store all messages unfiltered
        this.messages = result.regular;
        this.freightChatMessages = result.freight;
        this.originationChatMessages = result.origination;
        this.marketNewsMessages = result.marketNews;
        if (this.selectedCounterparty) {
          this.filterMessagesForCounterparty();
        }
      },
      error: (error) => {
        console.error('Error loading messages:', error);
      },
      complete: () => {
        console.log('Message loading complete');
        this.isLoadingMessages = false;
      }
    });
  }

  private filterMessagesForCounterparty() {
    console.log('Starting filterMessagesForCounterparty');
    if (!this.selectedCounterparty) {
      console.log('No selected counterparty, returning early');
      this.filteredMessages = {
        regular: [],
        freight: [],
        origination: [],
        marketNews: []
      };
      return;
    }

    const containsCounterpartyReference = (text: string, messageDate: Date): boolean => {
      // First check if message is within date range
      if (messageDate < this.startDate || messageDate > this.endDate) {
        return false;
      }

      if (!text || !this.selectedCounterparty) return false;
      
      // Convert text to lowercase for case-insensitive matching
      const normalizedText = text.toLowerCase();

      // Helper function to check if text matches any of the terms
      const matchesAnyTerm = (terms: string | undefined | null): boolean => {
        if (!terms) return false;
        const termsList = terms.toLowerCase().split(',');
        return termsList
          .map(term => term.trim())
          .some(term => {
            if (!term) return false;
            // Create a regex pattern that matches whole words only
            const pattern = new RegExp(`\\b${term}\\b`, 'i');
            return pattern.test(normalizedText);
          });
      };

      // 1. Check Allegro Code
      if (this.selectedCounterparty.allegroCode) {
        const pattern = new RegExp(`\\b${this.selectedCounterparty.allegroCode.toLowerCase()}\\b`, 'i');
        if (pattern.test(normalizedText)) {
          console.log('✓ MATCHED by Allegro Code');
          return true;
        }
      }

      // 2. Check Shortname
      if (this.selectedCounterparty.shortname) {
        const pattern = new RegExp(`\\b${this.selectedCounterparty.shortname.toLowerCase()}\\b`, 'i');
        if (pattern.test(normalizedText)) {
          console.log('✓ MATCHED by Shortname');
          return true;
        }
      }

      // 3. Check Other Terms
      if (matchesAnyTerm(this.selectedCounterparty.otherTerms)) {
        console.log('✓ MATCHED by Other Terms 1');
        return true;
      }
      if (matchesAnyTerm(this.selectedCounterparty.otherTerms2)) {
        console.log('✓ MATCHED by Other Terms 2');
        return true;
      }
      if (matchesAnyTerm(this.selectedCounterparty.otherTerms3)) {
        console.log('✓ MATCHED by Other Terms 3');
        return true;
      }

      // 4. Check Parent Company matches (only if includeRelatedCompanies is true)
      if (this.includeRelatedCompanies && this.selectedCounterparty.mapToParent) {
        const parentCompany = this.counterparties.find(
          cp => cp.allegroCode === this.selectedCounterparty?.mapToParent
        );
        
        if (parentCompany) {
          // Check parent's allegro code
          if (parentCompany.allegroCode && 
              normalizedText.includes(parentCompany.allegroCode.toLowerCase())) {
            console.log('✓ MATCHED by Parent Allegro Code');
            return true;
          }

          // Check parent's shortname
          if (parentCompany.shortname && 
              normalizedText.includes(parentCompany.shortname.toLowerCase())) {
            console.log('✓ MATCHED by Parent Shortname');
            return true;
          }

          // Check parent's other terms
          if (matchesAnyTerm(parentCompany.otherTerms)) {
            console.log('✓ MATCHED by Parent Other Terms 1');
            return true;
          }
          if (matchesAnyTerm(parentCompany.otherTerms2)) {
            console.log('✓ MATCHED by Parent Other Terms 2');
            return true;
          }
          if (matchesAnyTerm(parentCompany.otherTerms3)) {
            console.log('✓ MATCHED by Parent Other Terms 3');
            return true;
          }

          // Add sibling company checks
          if (parentCompany) {
            // Find all sibling companies (other children of the same parent)
            const siblingCompanies = this.counterparties.filter(
              cp => cp.mapToParent === this.selectedCounterparty?.mapToParent &&
                   cp.allegroCode !== this.selectedCounterparty?.allegroCode
            );
            
            for (const siblingCompany of siblingCompanies) {
              // Check sibling's allegro code
              if (siblingCompany.allegroCode && 
                  normalizedText.includes(siblingCompany.allegroCode.toLowerCase())) {
                console.log('✓ MATCHED by Sibling Allegro Code');
                return true;
              }
              
              // Check sibling's shortname
              if (siblingCompany.shortname && 
                  normalizedText.includes(siblingCompany.shortname.toLowerCase())) {
                console.log('✓ MATCHED by Sibling Shortname');
                return true;
              }
              
              // Check sibling's other terms
              if (matchesAnyTerm(siblingCompany.otherTerms)) {
                console.log('✓ MATCHED by Sibling Other Terms 1');
                return true;
              }
              if (matchesAnyTerm(siblingCompany.otherTerms2)) {
                console.log('✓ MATCHED by Sibling Other Terms 2');
                return true;
              }
              if (matchesAnyTerm(siblingCompany.otherTerms3)) {
                console.log('✓ MATCHED by Sibling Other Terms 3');
                return true;
              }
            }
          }
        }
      }

      // 5. Check Child Companies (new code, only if includeRelatedCompanies is true)
      if (this.includeRelatedCompanies) {
        // Find all child companies that map to this counterparty
        const childCompanies = this.counterparties.filter(
          cp => cp.mapToParent === this.selectedCounterparty?.allegroCode
        );

        for (const childCompany of childCompanies) {
          // Check child's allegro code
          if (childCompany.allegroCode && 
              normalizedText.includes(childCompany.allegroCode.toLowerCase())) {
            console.log('✓ MATCHED by Child Allegro Code');
            return true;
          }

          // Check child's shortname
          if (childCompany.shortname && 
              normalizedText.includes(childCompany.shortname.toLowerCase())) {
            console.log('✓ MATCHED by Child Shortname');
            return true;
          }

          // Check child's other terms
          if (matchesAnyTerm(childCompany.otherTerms)) {
            console.log('✓ MATCHED by Child Other Terms 1');
            return true;
          }
          if (matchesAnyTerm(childCompany.otherTerms2)) {
            console.log('✓ MATCHED by Child Other Terms 2');
            return true;
          }
          if (matchesAnyTerm(childCompany.otherTerms3)) {
            console.log('✓ MATCHED by Child Other Terms 3');
            return true;
          }
        }
      }

      return false;
    };

    // Filter messages
    const filtered = {
      regular: [] as any[],
      freight: [] as any[],
      origination: [] as any[],
      marketNews: [] as any[]
    };

    if (Array.isArray(this.messages)) {
      filtered.regular = this.messages
        .filter(message => {
          const decodedMessage = this.decodeURIComponent(message.message);
          const textToCheck = `${message.subject} ${decodedMessage}`;
          const messageDate = new Date(message.date);
          return containsCounterpartyReference(textToCheck, messageDate);
        })
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    }

    if (Array.isArray(this.freightChatMessages)) {
      filtered.freight = this.freightChatMessages
        .filter(message => {
          const decodedMessage = this.decodeURIComponent(message.message);
          const textToCheck = `${message.subject} ${decodedMessage}`;
          const messageDate = new Date(message.date);
          return containsCounterpartyReference(textToCheck, messageDate);
        })
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    }

    if (Array.isArray(this.originationChatMessages)) {
      filtered.origination = this.originationChatMessages
        .filter(message => {
          const decodedMessage = this.decodeURIComponent(message.message);
          const textToCheck = `${message.subject} ${decodedMessage}`;
          const messageDate = new Date(message.date);
          return containsCounterpartyReference(textToCheck, messageDate);
        })
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    }

    if (Array.isArray(this.marketNewsMessages)) {
      filtered.marketNews = this.marketNewsMessages
        .filter(message => {
          const decodedMessage = this.decodeURIComponent(message.message);
          const textToCheck = `${message.subject} ${decodedMessage}`;
          const messageDate = new Date(message.date);
          return containsCounterpartyReference(textToCheck, messageDate);
        })
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    }

    this.filteredMessages = filtered;
    
    console.log('Filtered messages:', {
      regular: filtered.regular.length,
      freight: filtered.freight.length,
      origination: filtered.origination.length,
      marketNews: filtered.marketNews.length
    });
  }

  decodeURIComponent(text: string): string {
    try {
      return decodeURIComponent(text);
    } catch (e) {
      console.error('Failed to decode message:', e);
      return text;
    }
  }

  private loadTrades(): void {
    this.isLoadingTrades = true;
    this.tradeError = null;
    
    // Load all trades once, store them unfiltered
    this.tradeService.getTrades().pipe(
      retry(3),
      catchError(error => {
        console.error('Error loading trades:', error);
        this.tradeError = 'Unable to load trades. Please try again later.';
        return of([]);
      }),
      finalize(() => {
        this.isLoadingTrades = false;
        console.log('Finished loading trades');
      })
    ).subscribe({
      next: (trades) => {
        this.trades = trades || [];
        if (this.selectedCounterparty) {
          this.filterTradesForCounterparty();
        }
      }
    });
  }

  private filterTradesForCounterparty(): void {
    if (!this.selectedCounterparty) {
      this.filteredTrades = [];
      return;
    }

    this.filteredTrades = this.trades.filter(trade => {
      // First check if trade is within date range
      const tradeDate = new Date(trade.tradeDate);
      if (tradeDate < this.startDate || tradeDate > this.endDate) {
        return false;
      }
      
      // Direct match with selected counterparty
      if (trade.counterparty === this.selectedCounterparty?.allegroCode) {
        return true;
      }
      
      // Check for parent company if this is a child company
      if (this.includeRelatedCompanies && this.selectedCounterparty && this.selectedCounterparty.mapToParent) {
        const parentCompany = this.counterparties.find(
          cp => cp.allegroCode === this.selectedCounterparty?.mapToParent
        );
        
        if (parentCompany && trade.counterparty === parentCompany.allegroCode) {
          return true;
        }
        
        // Add sibling company check
        const siblingCompanies = this.counterparties.filter(
          cp => cp.mapToParent === this.selectedCounterparty?.mapToParent &&
               cp.allegroCode !== this.selectedCounterparty?.allegroCode
        );
        
        for (const siblingCompany of siblingCompanies) {
          if (trade.counterparty === siblingCompany.allegroCode) {
            return true;
          }
        }
      }
      
      // Check for child companies if this is a parent company
      if (this.includeRelatedCompanies) {
        const childCompanies = this.counterparties.filter(
          cp => cp.mapToParent === this.selectedCounterparty?.allegroCode
        );
        
        for (const childCompany of childCompanies) {
          if (trade.counterparty === childCompany.allegroCode) {
            return true;
          }
        }
      }
      
      return false;
    });
  }

  private updateDateRangeString(): void {
    this.dateRange = `${this.datePipe.transform(this.startDate, 'MMM d, yyyy')} - ${this.datePipe.transform(this.endDate, 'MMM d, yyyy')}`;
  }

  onDateRangeChange(start: Date, end: Date): void {
    this.startDate = start;
    this.endDate = end;
    this.updateDateRangeString();
    
    if (this.selectedCounterparty) {
      this.filterMessagesForCounterparty();
      this.filterTradesForCounterparty();
      this.filterPlattsTradesForCounterparty();
    }
  }

  openDatePicker(datepicker: MatDateRangePicker<Date>): void {
    datepicker.open();
  }

  private loadPlattsTrades(): void {
    this.isLoadingPlattsTrades = true;
    this.plattsTradeError = null;
    
    this.plattsTradeService.getCounterpartyTrades(this.startDate, this.endDate).pipe(
      retry(3),
      catchError(error => {
        console.error('Error loading Platts trades:', error);
        this.plattsTradeError = 'Unable to load Platts trades. Please try again later.';
        return of([]);
      }),
      finalize(() => {
        this.isLoadingPlattsTrades = false;
      })
    ).subscribe({
      next: (trades) => {
        this.plattsTrades = trades || [];
        if (this.selectedCounterparty) {
          this.filterPlattsTradesForCounterparty();
        }
      }
    });
  }

  private filterPlattsTradesForCounterparty(): void {
    if (!this.selectedCounterparty || !Array.isArray(this.plattsTrades)) {
      this.filteredPlattsTrades = { physical: [], financial: [] };
      return;
    }

    // Get all relevant Platts codes
    const plattsCodes: string[] = [];
    
    // Only add the selected counterparty's code if it exists
    if (this.selectedCounterparty.plattsCode) {
      plattsCodes.push(this.selectedCounterparty.plattsCode.toLowerCase());
    }
    
    // Add parent/child codes if enabled
    if (this.includeRelatedCompanies) {
      // If this is a parent company, add all child company codes
      const childCompanies = this.counterparties.filter(
        cp => cp.mapToParent === this.selectedCounterparty?.allegroCode
      );
      
      for (const child of childCompanies) {
        if (child.plattsCode) {
          plattsCodes.push(child.plattsCode.toLowerCase());
        }
      }
      
      // If this is a child company, add the parent company code
      if (this.selectedCounterparty.mapToParent) {
        const parentCompany = this.counterparties.find(
          cp => cp.allegroCode === this.selectedCounterparty?.mapToParent
        );
        
        if (parentCompany && parentCompany.plattsCode) {
          plattsCodes.push(parentCompany.plattsCode.toLowerCase());
        }
        
        // Also add all sibling companies (other children of the same parent)
        const siblingCompanies = this.counterparties.filter(
          cp => cp.mapToParent === this.selectedCounterparty?.mapToParent &&
               cp.allegroCode !== this.selectedCounterparty?.allegroCode
        );
        
        for (const sibling of siblingCompanies) {
          if (sibling.plattsCode) {
            plattsCodes.push(sibling.plattsCode.toLowerCase());
          }
        }
      }
    }
    
    console.log('Using Platts codes for filtering:', plattsCodes);
    
    // If no valid Platts codes, return empty results
    if (plattsCodes.length === 0) {
      this.filteredPlattsTrades = { physical: [], financial: [] };
      return;
    }
    
    const filteredTrades = this.plattsTrades.filter(trade => {
      const buyerMnemonic = trade.buyerMnemonic?.toLowerCase() || '';
      const sellerMnemonic = trade.sellerMnemonic?.toLowerCase() || '';
      
      const isCounterpartyInvolved = plattsCodes.some(code => 
        buyerMnemonic === code || sellerMnemonic === code
      );
      
      const tradeDate = new Date(trade.orderDate);
      return isCounterpartyInvolved && tradeDate >= this.startDate && tradeDate <= this.endDate;
    });

    // Separate physical and financial trades based on whether market contains "derivative"
    const physicalTrades = filteredTrades.filter(trade => 
      !trade.market.toLowerCase().includes('derivative')
    );
    const financialTrades = filteredTrades.filter(trade => 
      trade.market.toLowerCase().includes('derivative')
    );
    
    // Process trades by month
    this.filteredPlattsTrades = {
      physical: this.summarizeTradesByMonth(physicalTrades),
      financial: this.summarizeTradesByMonth(financialTrades)
    };
    
    console.log(`Found ${physicalTrades.length} physical and ${financialTrades.length} financial Platts trades`);
  }

  private summarizeTradesByMonth(trades: CounterpartyTrade[]): PlattsTradesSummary[] {
    const monthlyData = new Map<string, PlattsTradesSummary>();
    
    // Get all relevant Platts codes
    const plattsCodes: string[] = [];
    
    if (this.selectedCounterparty!.plattsCode) {
      plattsCodes.push(this.selectedCounterparty!.plattsCode.toLowerCase());
    }
    
    // Add parent/child codes if enabled
    if (this.includeRelatedCompanies) {
      // If this is a parent company, add all child company codes
      const childCompanies = this.counterparties.filter(
        cp => cp.mapToParent === this.selectedCounterparty?.allegroCode
      );
      
      for (const child of childCompanies) {
        if (child.plattsCode) {
          plattsCodes.push(child.plattsCode.toLowerCase());
        }
      }
      
      // If this is a child company, add the parent company code
      if (this.selectedCounterparty!.mapToParent) {
        const parentCompany = this.counterparties.find(
          cp => cp.allegroCode === this.selectedCounterparty?.mapToParent
        );
        
        if (parentCompany && parentCompany.plattsCode) {
          plattsCodes.push(parentCompany.plattsCode.toLowerCase());
        }
        
        // Also add all sibling companies (other children of the same parent)
        const siblingCompanies = this.counterparties.filter(
          cp => cp.mapToParent === this.selectedCounterparty?.mapToParent &&
               cp.allegroCode !== this.selectedCounterparty?.allegroCode
        );
        
        for (const sibling of siblingCompanies) {
          if (sibling.plattsCode) {
            plattsCodes.push(sibling.plattsCode.toLowerCase());
          }
        }
      }
    }
    
    // Process trades by month
    trades.forEach(trade => {
      const date = new Date(trade.orderDate);
      const monthKey = this.datePipe.transform(date, 'MMM-yy')!;
      
      if (!monthlyData.has(monthKey)) {
        monthlyData.set(monthKey, {
          transactionMonth: monthKey,
          buyQty: 0,
          sellQty: 0,
          numberOfTrades: 0
        });
      }

      const summary = monthlyData.get(monthKey)!;
      const isPhysical = !trade.market.toLowerCase().includes('derivative');
      
      // For physical trades, count 1 trade = 1 cargo
      // For financial trades, use lotSize
      const quantityToAdd = isPhysical ? 1 : trade.lotSize;
      
      // Check against all relevant platts codes
      if (plattsCodes.includes(trade.buyerMnemonic.toLowerCase())) {
        summary.buyQty += quantityToAdd;
      } else if (plattsCodes.includes(trade.sellerMnemonic.toLowerCase())) {
        summary.sellQty += quantityToAdd;
      }
      summary.numberOfTrades++;
    });

    // Calculate net change for each month before returning
    return Array.from(monthlyData.values())
      .map(summary => ({
        ...summary,
        netChange: summary.buyQty - summary.sellQty
      }))
      .sort((a, b) => {
        // Parse the month-year format properly
        const [monthA, yearA] = a.transactionMonth.split('-');
        const [monthB, yearB] = b.transactionMonth.split('-');
        
        // Convert month abbreviation to number (0-11)
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const monthNumA = months.indexOf(monthA);
        const monthNumB = months.indexOf(monthB);
        
        // Compare years first
        const fullYearA = 2000 + parseInt(yearA);
        const fullYearB = 2000 + parseInt(yearB);
        
        if (fullYearA !== fullYearB) {
          return fullYearA - fullYearB; // Sort by year ascending
        }
        
        // If years are the same, compare months
        return monthNumA - monthNumB; // Sort by month ascending
      });
  }

  // Helper function to format date to MMMyy format
  public formatDateToMMMyy(dateString: string | null): string {
    console.log('Input dateString:', dateString); // Debug log

    if (!dateString) {
      console.log('Date string is null or empty');
      return '';
    }

    try {
      // Ensure we're working with a valid date string
      const date = new Date(dateString);
      console.log('Parsed date:', date); // Debug log

      if (isNaN(date.getTime())) {
        console.log('Invalid date after parsing');
        return '';
      }

      const formatted = date.toLocaleDateString('en-US', { 
        month: 'short', 
        year: '2-digit'
      }).replace(' ', '');
      
      console.log('Formatted date:', formatted); // Debug log
      return formatted;

    } catch (error) {
      console.error('Error formatting date:', error);
      return '';
    }
  }

  public groupTradesByTradeId(trades: Trade[]): Trade[][] {
    const tradeMap = new Map<string, Trade[]>();
    
    trades.forEach(trade => {
      const tradeId = trade.tradeId;
      if (!tradeMap.has(tradeId)) {
        tradeMap.set(tradeId, []);
      }
      tradeMap.get(tradeId)?.push(trade);
    });

    return Array.from(tradeMap.values());
  }

  showDealTerms(monthSummary: PlattsTradesSummary): void {
    console.log('showDealTerms called with:', monthSummary);
    
    if (!this.selectedCounterparty) {
      console.log('No selected counterparty');
      return;
    }

    // Get all relevant Platts codes
    const plattsCodes: string[] = [];
    
    // Only add the selected counterparty's code if it exists
    if (this.selectedCounterparty.plattsCode) {
      plattsCodes.push(this.selectedCounterparty.plattsCode.toLowerCase());
    }
    
    // Add parent/child codes if enabled
    if (this.includeRelatedCompanies) {
      // If this is a parent company, add all child company codes
      const childCompanies = this.counterparties.filter(
        cp => cp.mapToParent === this.selectedCounterparty?.allegroCode
      );
      
      for (const child of childCompanies) {
        if (child.plattsCode) {
          plattsCodes.push(child.plattsCode.toLowerCase());
        }
      }
      
      // If this is a child company, add the parent company code
      if (this.selectedCounterparty.mapToParent) {
        const parentCompany = this.counterparties.find(
          cp => cp.allegroCode === this.selectedCounterparty?.mapToParent
        );
        
        if (parentCompany && parentCompany.plattsCode) {
          plattsCodes.push(parentCompany.plattsCode.toLowerCase());
        }
        
        // Also add all sibling companies
        const siblingCompanies = this.counterparties.filter(
          cp => cp.mapToParent === this.selectedCounterparty?.mapToParent &&
               cp.allegroCode !== this.selectedCounterparty?.allegroCode
        );
        
        for (const sibling of siblingCompanies) {
          if (sibling.plattsCode) {
            plattsCodes.push(sibling.plattsCode.toLowerCase());
          }
        }
      }
    }
    
    console.log('Using Platts codes for modal:', plattsCodes);
    
    // Find the trades for this month
    const monthTrades = this.plattsTrades.filter(trade => {
      // Extract month from trade date to match the summary month format
      const tradeDate = new Date(trade.orderDate);
      const tradeMonth = this.datePipe.transform(tradeDate, 'MMM-yy');
      
      // Check if trade is for the selected month
      if (tradeMonth !== monthSummary.transactionMonth) {
        return false;
      }
      
      // Check if it's a physical trade (not a derivative)
      if (trade.market.toLowerCase().includes('derivative')) {
        return false;
      }
      
      // Check if trade is for the selected counterparty or related companies
      return plattsCodes.some(code => 
        trade.buyerMnemonic?.toLowerCase() === code || 
        trade.sellerMnemonic?.toLowerCase() === code
      );
    });
    
    console.log('Found trades for modal:', monthTrades);
    
    if (monthTrades.length === 0) {
      console.log('No trades found for this month');
      return;
    }
    
    // Open the modal with the trades
    const dialogRef = this.dialog.open(DealTermsModalComponent, {
      width: '800px',
      data: {
        trades: monthTrades
      }
    });
    
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  // Update the toggleSection method to remove the event parameter
  toggleSection(section: keyof typeof this.sectionStates): void {
    this.sectionStates[section] = !this.sectionStates[section];
  }

  onRelatedCompaniesToggle(): void {
    // Refilter all data when the checkbox changes
    this.filterMessagesForCounterparty();
    this.filterTradesForCounterparty();
    this.filterPlattsTradesForCounterparty();
  }

  truncateSubject(subject: string, maxLength: number): string {
    if (!subject) return '';
    return subject.length > maxLength ? subject.substring(0, maxLength) + '...' : subject;
  }
}