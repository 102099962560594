<div class="login-screen-wrapper">
    <img src="../assets/jera-grey.svg" class="login-screen-logo">
    <h1>
        {{applicationName}}
    </h1>
    <p>
        To view this web page, please log in.
    </p>
    <button matTooltip="You will be redirected to log in" color="primary" mat-raised-button (click)="login()"
        class="login-button">
        Log In
    </button>
</div>