import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-theme-toggle',
  templateUrl: './theme-toggle.component.html',
  styleUrls: ['./theme-toggle.component.scss']
})
export class ThemeToggleComponent implements AfterViewInit {

  @ViewChild('darkModeSwitch', { read: ElementRef }) darkModeSwitch: ElementRef | undefined;
  checked = false;
  
  constructor(private renderer: Renderer2) {}


  ngAfterViewInit() {
    this.setIcon();
  }

  setIcon() {
    if (this.darkModeSwitch) {
      const targetSpan: HTMLElement = this.darkModeSwitch.nativeElement.querySelector('.mdc-switch__icons');
      while (targetSpan.firstChild) {
        targetSpan.firstChild.remove();
      }
      const elem = this.renderer.createElement('mat-icon');
      const icon = this.checked ? 'dark_mode' : 'light_mode';
      elem.setAttribute('class', 'mat-icon notranslate material-icons mat-icon-no-color light-mode-switch-icon');
      elem.textContent = icon;
      targetSpan.appendChild(elem);
    }
  }

  changeTheme() {
    this.checked = !this.checked;
    this.setIcon();
  }
}