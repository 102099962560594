<div class="deal-terms-modal">
  <h2 mat-dialog-title>Deal Terms for {{datePipe.transform(data.trades[0].orderDate, 'MMM yyyy')}}</h2>
  
  <mat-dialog-content>
    <div class="trades-container">
      <div *ngFor="let trade of data.trades" class="trade-item">
        <div class="trade-header">
          <strong>Trade Date:</strong> {{formatDate(trade.orderDate)}}
        </div>
        
        <div class="deal-terms" *ngIf="trade.dealTerms">
          <pre>{{trade.dealTerms}}</pre>
        </div>
        
        <div class="trade-details">
          <p><strong>Buyer:</strong> {{trade.buyerMnemonic}}</p>
          <p><strong>Seller:</strong> {{trade.sellerMnemonic}}</p>
          <p><strong>Market:</strong> {{trade.market}}</p>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  
  <mat-dialog-actions align="end">
    <button mat-button (click)="onClose()">Close</button>
  </mat-dialog-actions>
</div>
