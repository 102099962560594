// third party
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// local
import { HomeComponent } from './home/home.component';



@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule
  ],
  exports: [
    HomeComponent,
  ]
})
export class HomeModule { }
