import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table'  
import { CommonModule } from "@angular/common";
import { MessageService } from './message.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DatePipe } from '@angular/common';

interface Message {
    message: string;
    messageDates: string[];
    user: string;
    timestamp: string; 
}

interface FilteredMessage {
    name: string;
    year: string;
    messages: { text: string; header: string; timestamp: string }[]; 
    expanded: boolean;  // Change from optional to required boolean
}

@Component({
  selector: 'app-market-blotter',
  templateUrl: './marketblotter.component.html',
  styleUrls: ['./marketblotter.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    CommonModule,
    MatAutocompleteModule
    ],
  providers: [DatePipe]
})
export class MarketBlotterComponent implements OnInit {
  title = 'Market Blotter';
  periods = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Q1', 'Q2', 'Q3', 'Q4', 'Summer', 'Winter'];
  years: number[] = this.generateYears(); 
  selectedPeriod = '';
  selectedYear: number | null = null;
  searchTerm = '';
  messages: { [index: string]: Message[] } = {}
  filteredMessages: FilteredMessage[] = [];
  searchError: string = '';
  searchOnlyMode = false;

  // Add this new method
  private generateYears(): number[] {
      const startYear = 2024;
      const currentYear = new Date().getFullYear();
      const numberOfYearsAhead = 2; // You can adjust this number to show more future years
      const endYear = currentYear + numberOfYearsAhead;
      
      const years: number[] = [];
      for (let year = startYear; year <= endYear; year++) {
        years.push(year);
      }
      return years;
    }

  constructor(private messageService: MessageService, private datePipe: DatePipe) { }

  loadMessages() {
    this.messageService.getMessages().subscribe(data => {
      console.log(data)
      this.messages = this.processData(data);
      this.decodeMessages();
      
      
      console.log(this.messages)

      console.log('Processed Messages:', this.messages); // Log the processed messages
    }, error => {
      console.error('Error fetching messages:', error); // Log any errors from the API call
    });
  }

  processData(data: any): { [index: string]: Message[] } {
    console.log('Raw data:', data);
    const processedMessages: { [index: string]: Message[] } = {};
    data.forEach((item: any) => {
        const key = item.subject;
        if (!processedMessages[key]) {
            processedMessages[key] = [];
        }

        // Handle null or missing messageDates
        let messageDates: string[] = [];
        if (item.messageDates) {
            messageDates = item.messageDates.split(',')
                .map((date: string) => date.trim())
                .filter((date: string) => date)
                .map((date: string) => {
                    const month = date.slice(0, 3);
                    const year = date.slice(3);
                    return `${month} ${year}`;
                });
        }

        processedMessages[key].push({
            message: item.message,
            messageDates: messageDates, // Will be empty array if messageDates was null/undefined
            user: item.user,
            timestamp: item.rowKey
        });
    });
    console.log('Processed messages:', processedMessages);
    return processedMessages;
  }

  decodeMessages() {
    const removeHashtag = (key: string) => key.replace(/^#/, '');
    const updatedMessages: { [index: string]: Message[] } = {};

    for (const key in this.messages) {
      if (this.messages.hasOwnProperty(key)) {
        const newKey = removeHashtag(key);
        updatedMessages[newKey] = this.messages[key].map(message => {
          return {
            ...message,
            message: decodeURIComponent(message.message).replace(/\n/g, '')
          };
        });
      }
    }

    this.messages = updatedMessages;
  }

  onPeriodSelection(selectedPeriod: string) {
    this.selectedPeriod = selectedPeriod;
    console.log(this.selectedPeriod)
  }

  onYearSelection(selectedYear: number) {
    this.selectedYear = selectedYear;
    console.log(this.selectedYear)
  }

  ngOnInit() {
    this.loadMessages();
    console.log('Periods:', this.periods);    
    console.log('Years:', this.years);
  }

  isValidSearch(): boolean {
    if (this.selectedPeriod && this.selectedYear) {
      // Period and Year are filled - search term is optional
      this.searchError = '';
      return true;
    } else if (!this.selectedPeriod && !this.selectedYear && this.searchTerm.trim()) {
      // Period and Year are empty but search term exists
      this.searchError = '';
      return true;
    }
    
    this.searchError = 'Please either enter both Period and Year, or enter a search term without Period/Year';
    return false;
  }

  private sortMessagesByTimestamp(messages: { text: string; header: string; timestamp: string }[]) {
    return messages.sort((a, b) => {
        const timeA = new Date(a.timestamp).getTime();
        const timeB = new Date(b.timestamp).getTime();
        return timeB - timeA; // Descending order
    });
  }

  onSearch() {
    if (!this.isValidSearch()) {
      return;
    }
    
    this.searchOnlyMode = !this.selectedPeriod && !this.selectedYear && !!this.searchTerm.trim();
    this.filteredMessages = [];
    const searchTermLower = this.searchTerm.toLowerCase();
    const periodMonths = this.getPeriodMonths(this.selectedPeriod);
    
    const uniqueMessages = new Set<string>();
    console.log(this.searchOnlyMode)
    if (this.searchOnlyMode) {
        // Handle search-only mode
        const searchResults: FilteredMessage = {
            name: 'Search Results',
            year: '',
            messages: [],
            expanded: true    // Explicitly set as boolean
        };

        console.log(this.searchOnlyMode)

        for (const key in this.messages) {
            if (this.messages.hasOwnProperty(key)) {
                const messages = this.messages[key];
                messages.forEach(message => {
                    if (key.toLowerCase().includes(searchTermLower) ||
                        message.message.toLowerCase().includes(searchTermLower) ||
                        message.user.toLowerCase().includes(searchTermLower)) {
                        
                        const formattedTimestamp = this.datePipe.transform(message.timestamp, 'medium') || '';
                        const uniqueKey = `${message.message}-${message.user}-${formattedTimestamp}`;
                        
                        if (!uniqueMessages.has(uniqueKey)) {
                            uniqueMessages.add(uniqueKey);
                            searchResults.messages.push({
                                text: message.message,
                                header: `${key} | ${message.user}`,
                                timestamp: formattedTimestamp
                            });
                        }
                    }
                });
            }
        }

        if (searchResults.messages.length > 0) {
            searchResults.messages = this.sortMessagesByTimestamp(searchResults.messages);
            this.filteredMessages = [searchResults];
        }
    } else {
        // Existing period/year filtering logic
        const uniqueMessages = new Set<string>();
        console.log(this.messages)
        for (const key in this.messages) {
            if (this.messages.hasOwnProperty(key)) {
                const messages = this.messages[key];
                console.log(messages)
                messages.forEach(message => {
                    // Check if the search term is in the key, message text, or user
                    if (!this.searchTerm || key.toLowerCase().includes(searchTermLower) ||
                        message.message.toLowerCase().includes(searchTermLower) ||
                        message.user.toLowerCase().includes(searchTermLower)) {

                        message.messageDates.forEach(date => {
                            const [month, year] = date.split(' ');

                            let isYearMatch = true;
                            let isPeriodMatch = true;

                            if (this.selectedYear) {
                                isYearMatch = periodMonths.some(pm => parseInt(year) === this.selectedYear! + pm.yearOffset && pm.month === month);
                            }

                            if (this.selectedPeriod) {
                                isPeriodMatch = periodMonths.some(pm => pm.month === month);
                            }

                            if (isYearMatch && isPeriodMatch) {
                                let periodLabel = this.selectedPeriod || month;
                                let periodMessages = this.filteredMessages.find(m => m.name === periodLabel && m.year === this.selectedYear?.toString());
                                if (!periodMessages) {
                                    periodMessages = { 
                                        name: periodLabel, 
                                        year: this.selectedYear?.toString() || year, 
                                        messages: [],
                                        expanded: true  // Add the missing expanded property
                                    };
                                    this.filteredMessages.push(periodMessages);
                                }
                                const formattedTimestamp = this.datePipe.transform(message.timestamp, 'medium') || '';
                                const uniqueKey = `${message.message}-${message.user}-${formattedTimestamp}`;
                                if (!uniqueMessages.has(uniqueKey)) {
                                    uniqueMessages.add(uniqueKey);
                                    periodMessages.messages.push({ text: message.message, header: `${key} | ${message.user}`, timestamp: formattedTimestamp });
                                }
                            }
                        });
                    }
                });
            }
        }

        console.log(this.filteredMessages);

        // Sort the filteredMessages by year and period
        const periodOrder = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Q1', 'Q2', 'Q3', 'Q4', 'Spring', 'Summer', 'Fall', 'Winter'];

        this.filteredMessages.sort((a, b) => {
            const yearDiff = parseInt(a.year) - parseInt(b.year);
            if (yearDiff !== 0) {
                return yearDiff;
            }
            return periodOrder.indexOf(a.name) - periodOrder.indexOf(b.name);
        });
    }

    // After all messages are collected, sort messages within each filtered message group
    this.filteredMessages.forEach(fm => {
        fm.messages = this.sortMessagesByTimestamp(fm.messages);
    });

    console.log(this.filteredMessages);
}

    getPeriodMonths(period: string): { month: string, yearOffset: number }[] {
        if (!period) {
            return [
                { month: 'Jan', yearOffset: 0 }, { month: 'Feb', yearOffset: 0 }, { month: 'Mar', yearOffset: 0 },
                { month: 'Apr', yearOffset: 0 }, { month: 'May', yearOffset: 0 }, { month: 'Jun', yearOffset: 0 },
                { month: 'Jul', yearOffset: 0 }, { month: 'Aug', yearOffset: 0 }, { month: 'Sep', yearOffset: 0 },
                { month: 'Oct', yearOffset: 0 }, { month: 'Nov', yearOffset: 0 }, { month: 'Dec', yearOffset: 0 }
            ];
        }

        switch (period) {
            case 'Q1':
                return [{ month: 'Jan', yearOffset: 0 }, { month: 'Feb', yearOffset: 0 }, { month: 'Mar', yearOffset: 0 }];
            case 'Q2':
                return [{ month: 'Apr', yearOffset: 0 }, { month: 'May', yearOffset: 0 }, { month: 'Jun', yearOffset: 0 }];
            case 'Q3':
                return [{ month: 'Jul', yearOffset: 0 }, { month: 'Aug', yearOffset: 0 }, { month: 'Sep', yearOffset: 0 }];
            case 'Q4':
                return [{ month: 'Oct', yearOffset: 0 }, { month: 'Nov', yearOffset: 0 }, { month: 'Dec', yearOffset: 0 }];
            case 'Summer':
                return [{ month: 'Jun', yearOffset: 0 }, { month: 'Jul', yearOffset: 0 }, { month: 'Aug', yearOffset: 0 }];
            case 'Winter':
                return [{ month: 'Dec', yearOffset: 0 }, { month: 'Jan', yearOffset: 1 }, { month: 'Feb', yearOffset: 1 }];
            default:
                return [{ month: period, yearOffset: 0 }];
        }
    }
  toggleExpand(month: any) {
    month.expanded = !month.expanded;
    console.log(month.expanded)
  }

  boldHeader(header: string): string {
    const [boldPart, rest] = header.split(' | ');
    const capitalizedBoldPart = boldPart.toUpperCase();
    return `<b>${capitalizedBoldPart}</b> | ${rest}`;
  }
}