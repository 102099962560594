// third party
import { Component, OnInit } from '@angular/core';
// local
import { ProfileService } from '../../core/services/profile/profile.service';
import { Profile } from 'src/app/core/services/profile/profile';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  profile?: Profile;

  constructor(protected readonly profileService: ProfileService) {
  }

  ngOnInit() {
    this.profileService.getProfile().subscribe((profile) => {
      this.profile = profile;
    });
  }
}
