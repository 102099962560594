// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from './ienvironment';

export const environment: IEnvironment = {
    production: true,
    applicationName: "LNG Counterparty Intelligence Dashboard - Dev",
    applicationApi: "https://jgm-uks-dev-lci-ctpy-intel-app.azurewebsites.net",
    microsoftProfileGraphApi: "https://graph.microsoft.com/v1.0/me",
    applicationInsights: {
        connectionString: "InstrumentationKey=a6febc34-629d-419b-bd1e-f3b745a7def7;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/",
    },
    msal: {
        clientId: "a4b7e5d0-aed4-498d-af0c-4749267cdd86",
        authority: "https://login.microsoftonline.com/ceb24f8a-927b-4ff0-b5d2-1d38878821c2",
        redirectUri: "https://lng-counterparty-intelligence-dev.jeragm.com",
    },
    mainApi: {
        rootUrl: "https://jgm-uks-dev-lci-ctpy-intel-app.azurewebsites.net/",
        scopes: ["api://5de31630-2f11-42a9-ab37-ab7edccf8c5a/Api.Access"]
        // example
        // rootUrl: 'https://localhost:7016/api',
        // scopes: ['api://<GUID>/Api.Access']
    }
};

