<div class="display-container" [ngClass]="setTheme(darkThemeService.isDarkTheme.getValue())">
  <!-- START AUTHENTICATING -->
  <div *ngIf="isLoading" class="loading-screen-container">
    <app-loading-screen></app-loading-screen>
  </div>
  <!-- END AUTHENTICATING -->

  <!-- START NOT AUTHENTICATED -->
  <div *ngIf="isUnAuthenticated" class="login-screen-container">
    <app-login-screen></app-login-screen>
  </div>
  <!-- END NOT AUTHENTICATED -->

  <!-- START AUTHENTICATED -->
  <div *ngIf="isAuthenticated" class="authenticated-screen-container">
    <app-navbar></app-navbar>
    <app-side-nav class="side-nav"></app-side-nav>
  </div>
  <!-- END AUTHENTICATED -->
</div>