<div class="summary-report-container">
  <div class="header-section">
    <h1 class="main-title">Summary Report</h1>
    <div class="date-range-container">
      <mat-form-field>
        <mat-label>Change Date Range</mat-label>
        <mat-date-range-input 
          [formGroup]="dateRangeForm" 
          [rangePicker]="dateRangePicker"
          [disabled]="isLoading">
          <input matStartDate formControlName="start" placeholder="Start date">
          <input matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="dateRangePicker" [disabled]="isLoading"></mat-datepicker-toggle>
        <mat-date-range-picker #dateRangePicker>
          <mat-date-range-picker-actions>
            <button mat-button matDatepickerCancel>Cancel</button>
            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
          </mat-date-range-picker-actions>
        </mat-date-range-picker>
      </mat-form-field>
    </div>
    <button 
      class="btn-generate-pdf" 
      (click)="generatePDF()" 
      [disabled]="!selectedCounterparty || loading">
      <i class="fas fa-file-pdf"></i> Generate PDF
    </button>
  </div>
  
  <div class="card counterparty-selector-card">
    <div class="card-body">
      <div class="form-group">
        <div class="select-container">
          <select 
            id="counterparty"
            class="form-select"
            [class.disabled]="isLoading"
            [disabled]="isLoading"
            (change)="onCounterpartySelect($event)">
            <option value="">{{isLoading ? 'Loading data...' : 'Select a counterparty...'}}</option>
            <option *ngFor="let cp of counterparties" [value]="cp.id">
              {{cp.allegroName}} ({{cp.allegroCode}})
            </option>
          </select>
          
          <div *ngIf="isLoading" class="loading-overlay">
            <mat-spinner [diameter]="24"></mat-spinner>
            <span class="loading-text">
              {{loading ? 'Loading counterparties...' : 
                isLoadingMessages ? 'Loading messages...' : 
                isLoadingTrades ? 'Loading trades...' : 'Loading...'}}
            </span>
          </div>
        </div>
        
        <div *ngIf="error" class="alert alert-danger mt-2">
          <i class="fas fa-exclamation-circle"></i> {{error}}
        </div>
      </div>
      
      <div class="form-group mt-2" *ngIf="selectedCounterparty">
        <mat-checkbox 
          [(ngModel)]="includeRelatedCompanies"
          (change)="onRelatedCompaniesToggle()"
          [disabled]="isLoading">
          Include related companies
        </mat-checkbox>
      </div>
    </div>
  </div>

  <!-- Counterparty Details Section -->
  <div *ngIf="selectedCounterparty" class="card counterparty-details-card mt-4">
    <div class="card-header">
      <h2 class="card-title" (click)="toggleSection('counterpartyDetails')">
        <mat-icon>{{ sectionStates.counterpartyDetails ? 'expand_more' : 'chevron_right' }}</mat-icon>
        <i class="fas fa-building"></i>
        Counterparty Details
      </h2>
    </div>
    <div class="card-body" *ngIf="sectionStates.counterpartyDetails">
      <div class="details-grid">
        <div class="detail-item">
          <span class="detail-label">Allegro Name:</span>
          <span class="detail-value">{{selectedCounterparty.allegroName}}</span>
        </div>
        <div class="detail-item">
          <span class="detail-label">Approved For:</span>
          <span class="detail-value">{{selectedCounterparty.approvedFor}}</span>
        </div>
        <div class="detail-item">
          <span class="detail-label">Exposure Limit:</span>
          <span class="detail-value">{{selectedCounterparty.exposureLimit | currency:'USD':'symbol':'1.0-0'}}</span>
        </div>
        <div class="detail-item">
          <span class="detail-label">LNG MSA:</span>
          <span class="detail-value">
            {{selectedCounterparty.lngMsa === 'True' ? 
              selectedCounterparty.msaLink : 
              'Not Found'}}
          </span>
        </div>
      </div>
    </div>
  </div>

  <!-- Messages Section -->
  <div class="card messages-section mt-4" *ngIf="selectedCounterparty">
    <div class="card-body">
      <!-- Physical Market News -->
      <div class="market-section">
        <h3 class="section-title" (click)="toggleSection('physicalMarket')">
          <mat-icon>{{ sectionStates.physicalMarket ? 'expand_more' : 'chevron_right' }}</mat-icon>
          <mat-icon>chat</mat-icon>
          Physical Market News
        </h3>
        <div class="messages-list" *ngIf="sectionStates.physicalMarket">
          <div *ngIf="filteredMessages.regular.length === 0" class="no-messages-section">
            <mat-icon>info</mat-icon>
            <span>No physical market news found</span>
          </div>
          <div *ngFor="let message of filteredMessages.regular" class="message-item regular-message">
            <div class="message-content">
              <div class="message-header">
                <div class="message-tag">{{message.subject}}</div>
                <div class="message-meta">
                  <span class="message-timestamp">{{message.date | date:'MMM d, y h:mm a'}} - {{message.user}}</span>
                </div>
              </div>
              <div class="message-text">{{decodeURIComponent(message.message)}}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- Freight Market News -->
      <div class="market-section">
        <h3 class="section-title" (click)="toggleSection('freightMarket')">
          <mat-icon>{{ sectionStates.freightMarket ? 'expand_more' : 'chevron_right' }}</mat-icon>
          <mat-icon>chat_bubble</mat-icon>
          Freight Market News
        </h3>
        <div class="messages-list" *ngIf="sectionStates.freightMarket">
          <div *ngIf="filteredMessages.freight.length === 0" class="no-messages-section">
            <mat-icon>info</mat-icon>
            <span>No freight market news found</span>
          </div>
          <div *ngFor="let message of filteredMessages.freight" class="message-item freight-message">
            <div class="message-content">
              <div class="message-header">
                <div class="message-tag">{{message.subject}}</div>
                <div class="message-meta">
                  <span class="message-timestamp">{{message.date | date:'MMM d, y h:mm a'}} - {{message.user}}</span>
                </div>
              </div>
              <div class="message-text">{{decodeURIComponent(message.message)}}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- Origination Market News -->
      <div class="market-section">
        <h3 class="section-title" (click)="toggleSection('originationMarket')">
          <mat-icon>{{ sectionStates.originationMarket ? 'expand_more' : 'chevron_right' }}</mat-icon>
          <mat-icon>forum</mat-icon>
          Origination Market News
        </h3>
        <div class="messages-list" *ngIf="sectionStates.originationMarket">
          <div *ngIf="filteredMessages.origination.length === 0" class="no-messages-section">
            <mat-icon>info</mat-icon>
            <span>No origination market news found</span>
          </div>
          <div *ngFor="let message of filteredMessages.origination" class="message-item origination-message">
            <div class="message-content">
              <div class="message-header">
                <div class="message-tag">{{message.subject}}</div>
                <div class="message-meta">
                  <span class="message-timestamp">{{message.date | date:'MMM d, y h:mm a'}} - {{message.user}}</span>
                </div>
              </div>
              <div class="message-text">{{decodeURIComponent(message.message)}}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- Market News -->
      <div class="market-section">
        <h3 class="section-title" (click)="toggleSection('marketNews')">
          <mat-icon>{{ sectionStates.marketNews ? 'expand_more' : 'chevron_right' }}</mat-icon>
          <mat-icon>article</mat-icon>
          Market News
        </h3>
        <div class="messages-list" *ngIf="sectionStates.marketNews">
          <div *ngIf="filteredMessages.marketNews.length === 0" class="no-messages-section">
            <mat-icon>info</mat-icon>
            <span>No market news found</span>
          </div>
          <div *ngFor="let message of filteredMessages.marketNews" class="message-item market-news-message">
            <div class="message-content">
              <div class="message-header">
                <div class="message-tag" [title]="message.subject">{{truncateSubject(message.subject, 50)}}</div>
                <div class="message-meta">
                  <span class="message-timestamp">{{message.date | date:'MMM d, y h:mm a'}} - {{message.user}}</span>
                </div>
              </div>
              <div class="message-text">{{decodeURIComponent(message.message)}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Trades Section -->
  <div class="card messages-section mt-4" *ngIf="selectedCounterparty">
    <div class="card-body">
      <div class="market-section">
        <h3 class="section-title" (click)="toggleSection('jeraGmTrades')">
          <mat-icon>{{ sectionStates.jeraGmTrades ? 'expand_more' : 'chevron_right' }}</mat-icon>
          <mat-icon>receipt_long</mat-icon>
          JeraGM Trades
        </h3>
        <div class="messages-list" *ngIf="sectionStates.jeraGmTrades">
          <div *ngIf="filteredTrades.length === 0" class="no-messages-section">
            <mat-icon>info</mat-icon>
            <span>No trades found</span>
          </div>
          <div *ngFor="let trade of groupTradesByTradeId(filteredTrades)" class="trade-item">
            <div class="trade-content">
              <span class="trade-details">
                JeraGM {{trade[0].positionType}} {{trade[0].incoterms}} {{trade[0].location}}
                <ng-container *ngIf="trade[0].customTradeReference || trade[0].customPositionReference">
                  "<i>{{trade[0].customTradeReference || trade[0].customPositionReference}}</i>"
                </ng-container>
                <ng-container *ngIf="trade.length > 1">
                  {{formatDateToMMMyy(trade[0].begTime)}} - {{formatDateToMMMyy(trade[0].endTime)}}
                  ({{trade.length}} cargo strip)
                </ng-container>
                <ng-container *ngIf="trade.length === 1">
                  {{formatDateToMMMyy(trade[0].begTime)}}
                </ng-container>
                <span class="trade-date">
                  (Trade Date: {{trade[0].tradeDate | date:'MMM d, yyyy'}})
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Platts Section -->
  <div class="card messages-section mt-4" *ngIf="selectedCounterparty">
    <div class="card-body">
      <!-- Physical Trades -->
      <div class="market-section">
        <h3 class="section-title" (click)="toggleSection('plattsPhysical')">
          <mat-icon>{{ sectionStates.plattsPhysical ? 'expand_more' : 'chevron_right' }}</mat-icon>
          <mat-icon>directions_boat</mat-icon>
          Platts MOC Physical Trades
        </h3>
        <div *ngIf="sectionStates.plattsPhysical">
          <div class="trades-table-container" *ngIf="filteredPlattsTrades.physical.length > 0">
            <table class="trades-table">
              <thead>
                <tr>
                  <th>Transaction Month</th>
                  <th>Buy Qty (Cargoes)</th>
                  <th>Sell Qty (Cargoes)</th>
                  <th>Net Change</th>
                  <th>Number of Trades</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let summary of filteredPlattsTrades.physical" 
                    (click)="showDealTerms(summary)" 
                    class="clickable"
                    style="cursor: pointer;">
                  <td>{{summary.transactionMonth}}</td>
                  <td>{{summary.buyQty | number:'1.0-0'}}</td>
                  <td>{{summary.sellQty | number:'1.0-0'}}</td>
                  <td>{{summary.netChange | number:'1.0-0'}}</td>
                  <td>{{summary.numberOfTrades}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          
          <div *ngIf="filteredPlattsTrades.physical.length === 0" class="no-messages-section">
            <mat-icon>info</mat-icon>
            <span>No physical trades found</span>
          </div>
        </div>
      </div>

      <!-- Financial Trades -->
      <div class="market-section">
        <h3 class="section-title" (click)="toggleSection('plattsFinancial')">
          <mat-icon>{{ sectionStates.plattsFinancial ? 'expand_more' : 'chevron_right' }}</mat-icon>
          <mat-icon>trending_up</mat-icon>
          Platts Financial Trades
        </h3>
        <div *ngIf="sectionStates.plattsFinancial">
          <div class="trades-table-container" *ngIf="filteredPlattsTrades.financial.length > 0">
            <table class="trades-table">
              <thead>
                <tr>
                  <th>Transaction Month</th>
                  <th>Buy Qty (Lots)</th>
                  <th>Sell Qty (Lots)</th>
                  <th>Net Change</th>
                  <th>Number of Trades</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let summary of filteredPlattsTrades.financial">
                  <td>{{summary.transactionMonth}}</td>
                  <td>{{summary.buyQty | number:'1.0-0'}}</td>
                  <td>{{summary.sellQty | number:'1.0-0'}}</td>
                  <td>{{summary.netChange | number:'1.0-0'}}</td>
                  <td>{{summary.numberOfTrades}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          
          <div *ngIf="filteredPlattsTrades.financial.length === 0" class="no-messages-section">
            <mat-icon>info</mat-icon>
            <span>No financial trades found</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> 