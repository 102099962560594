<div class="loading-screen-container">
    <img src="../../../assets/jera-grey.svg" class="logo-loading-screen">
    <h1>
        {{applicationName}}
    </h1>
    <p>
        Checking authentication...
    </p>
    <mat-spinner></mat-spinner>
</div>
