import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { ProfileComponent } from './profile/profile/profile.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found/page-not-found.component';
import { HomeComponent } from './home/home/home.component';
import { FailedComponent } from './login/failed/failed.component';
import { MarketBlotterComponent } from './market-blotter/view-market-blotter/marketblotter.component';


const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'login-failed',
    component: FailedComponent
    },
  {
    path: 'market-blotter',
    component: MarketBlotterComponent
  },
  // ADD ALL NEW PAGES ABOVE THIS 404 PAGE
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
