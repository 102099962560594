// third party
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
// local
import { environment } from 'src/environments/environment';

@Injectable()
export class InsightsService {
    private readonly _appInsights;

    constructor(router: Router) {
        const angularPlugin = new AngularPlugin();
        this._appInsights =  new ApplicationInsights({
            config: {
                connectionString: environment.applicationInsights.connectionString,
                extensions: [angularPlugin],
                extensionConfig: {
                    [angularPlugin.identifier]: {
                        router: router,
                    },
                },
            },
        });
        this._appInsights.loadAppInsights();
    }

    get appInsights(): ApplicationInsights {
        return this._appInsights;
    }
}
