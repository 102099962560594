<div>
    <h1>{{ title }}</h1>
    <form>
        <div>
            <mat-form-field appearance="fill">
                <mat-label>Period</mat-label>
                <input type="text" matInput [(ngModel)]="selectedPeriod" name="period" [matAutocomplete]="autoPeriod">
                <mat-autocomplete #autoPeriod="matAutocomplete" (optionSelected)="onPeriodSelection($event.option.value)">
                    <mat-option *ngFor="let period of periods" [value]="period">{{ period }}</mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="fill">
                <mat-label>Year</mat-label>
                <input type="text" matInput [(ngModel)]="selectedYear" name="year" [matAutocomplete]="autoYear">
                <mat-autocomplete #autoYear="matAutocomplete" (optionSelected)="onYearSelection($event.option.value)">
                    <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="fill">
                <mat-label>Search Terms</mat-label>
                <input matInput [(ngModel)]="searchTerm" name="searchTerm" />
            </mat-form-field>
        </div>
        <div *ngIf="searchError" style="color: red; margin: 10px 0;">
            {{ searchError }}
        </div>
        <button mat-raised-button color="primary" 
                (click)="onSearch()"
                [disabled]="!((selectedPeriod && selectedYear) || (!selectedPeriod && !selectedYear && searchTerm.trim()))">
            Search
        </button>
    </form>

    <div *ngIf="filteredMessages.length > 0" style="margin-top: 20px;">
        <div *ngFor="let month of filteredMessages" class="month-container" style="margin-bottom: 20px;">
            <!-- Show expand button only when not in search-only mode -->
            <button mat-fab extended (click)="toggleExpand(month)" *ngIf="!searchOnlyMode">
                <mat-icon>{{ month.expanded ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
                {{ month.name }} {{ month.year }}
            </button>
            <!-- Show simple header for search-only mode -->
            <h2 *ngIf="searchOnlyMode">Search Results</h2>
            <table mat-table [dataSource]="month.messages" class="message-table" style="width: 100%; margin-top: 10px;"
                   *ngIf="month.expanded">
                <ng-container matColumnDef="header">
                    <th mat-header-cell *matHeaderCellDef class="header-column"> Entry </th>
                    <td mat-cell *matCellDef="let message">
                        <span [innerHTML]="boldHeader(message.header)"></span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="text">
                    <th mat-header-cell *matHeaderCellDef class="text-column"> Message </th>
                    <td mat-cell *matCellDef="let message" class="message-cell"> {{ message.text }} </td>
                </ng-container>
                <ng-container matColumnDef="timestamp">
                    <th mat-header-cell *matHeaderCellDef class="timestamp-column"> Time of Entry </th>
                    <td mat-cell *matCellDef="let message"> {{ message.timestamp }} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="['header', 'text', 'timestamp']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['header', 'text', 'timestamp'];"></tr>
            </table>
        </div>
    </div>
</div>