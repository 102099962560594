<mat-drawer-container [class]="isMenuOpen() ? 'side-nav__open' : 'side-nav__closed'">
    <mat-drawer mode="side" disableClose="true" opened="true">
        <button class="side-nav__button" mat-menu-item [routerLink]="['market-blotter']"
                [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active-link">
            <mat-icon>chat</mat-icon>
            <span class="side-nav__button-text">
                Market Blotter
            </span>
        </button>
    </mat-drawer>
    <mat-drawer-content style="margin-left: 0">
        <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
        <router-outlet *ngIf="!isIframe"></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>