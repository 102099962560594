// third party
import { Component } from '@angular/core';
// local
import { MenuOpenService } from 'src/app/core/services/menu-open/menu-open.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent {
  isIframe = false;
  constructor(
    private readonly menuOpenService: MenuOpenService,
  ) { }

  isMenuOpen(): boolean {
    return this.menuOpenService.isMenuOpen.getValue();
  }

}
