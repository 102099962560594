<div>
    <div *ngIf="profile">
        <p><strong>First Name: </strong> {{profile.givenName}}</p>
        <p><strong>Last Name: </strong> {{profile.surname}}</p>
        <p><strong>Email: </strong> {{profile.userPrincipalName}}</p>
        <p><strong>Id: </strong> {{profile.id}}</p>

        <h4>Roles:</h4>
        <ul>
            <li *ngFor="let role of profile.roles">{{ role }}</li>
        </ul>

        <p *ngIf="profile.hasRole('App.Admin')">This user HAS the <code>App.Admin</code> role.</p>
        <p *ngIf="!profile.hasRole('App.Admin')">This user DOES NOT have the <code>App.Admin</code> role.</p>
    </div>
    <div *ngIf="!profile">Retrieving profile...</div>
</div>
